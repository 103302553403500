import { GetterTree } from "vuex";
import { State } from "./state";
import _ from "lodash";

export const getters: GetterTree<State, State> = {
  /**  所有题目**/
  all_question(state) {
    const papers = state.papers || [];
    return papers.map((paper) => {
      const { paper_parts = [] } = paper as any;
      paper_parts.forEach((part: { questions: any[] } = { questions: [] }) => {
        part.questions = part.questions.map((question: any, index) => {
          const _question = {
            ...question,
            paper_id: paper.id,
            indexInQuestion: index + 1,
          };
          if (question.type === 11) {
            _question.sub_questions.forEach(
              (sQuestion: any, sIndex: number) => {
                sQuestion.paper_id = paper.id;
                sQuestion.indexInQuestion = sIndex + 1;
              }
            );
          }
          return _question;
        });
      });
      const questions = _.flatten(
        paper_parts.map((part: any) => part.questions) || []
      );
      const total_answer_question_num = questions.filter((question: any) =>
        question.type !== 11
          ? question.answer_params &&
            Object.values(question.answer_params || {}).length > 0
          : question.sub_questions
              .map((sub: any) => sub.answer_params)
              .filter((sa: any) => sa && Object.values(sa || {}).length > 0)
              .length === question.sub_questions.length
      ).length;
      const total_answer_question_num2 = questions.filter((question: any) =>
        question.type !== 11
          ? question.answer_params
          : question.sub_questions
              .map((sub: any) => sub.answer_params)
              .filter(Boolean).length === question.sub_questions.length
      ).length;
      const total_question_num2 = questions.reduce(
        (total: number, question: any) => {
          if (question.type !== 11) {
            total++;
          } else {
            total += (question.sub_questions || []).length;
          }
          return total;
        },
        0
      );

      return {
        ...paper,
        total_answer_question_num,
        total_answer_question_num2,
        total_question_num2,
      };
    });
  },
};
