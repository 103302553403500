import axios from "axios";
import store from "@/monitoring-store";

/**
 *
 * @param method
 * @param url
 * @param params
 */
const request = (
  method: string,
  url: string,
  params?: any,
  timeout = 10 * 1000
) => {
  const handlerError = (error: any, reject: any) => {
    reject(error);
    return;
  };
  const baseurl =
    "https://" + store.state.localIp + ":" + store.state.localExamServicesPort;
  return new Promise<any>((resolve, reject) => {
    axios({
      method,
      baseURL: baseurl,
      url,
      timeout,
      params: method === "GET" ? params : null, // 是即将与请求一起发送的 URL 参数
      data: method === "POST" || method === "PUT" ? params : null, // 是作为请求主体被发送的数据
    } as any)
      .then((res: any) => {
        const data = res.data;
        if (data.status_code !== undefined) {
          if (data.status_code === 0) {
            resolve(data.data);
          } else {
            handlerError(res, reject);
          }
        } else {
          resolve(res.data);
        }
      })
      .catch((error: any) => {
        handlerError(error, reject);
      });
  });
};

export default request;
