import { MutationTree } from "vuex";
import { ExamStatus, State, Student } from "./state";
import logger from "@evideo/logger";
import _ from "lodash";

export const MutationNames = {
  CHANGE_EXAM_STATUS: "changeExamStatus",
  CHANGE_STUDENTS: "changeStudents",
  CHANGE_STUDENT: "changeStudent",
  CHANGE_CONNECT_INFO: "changeConnectInfo",
  CHANGE_ACTIVE_EXAMS: "changeActiveExams",
  CHANGE_PAPERS: "changePapers",
  CHANGE_CURRENT_SERIAL: "changeCurrentSerial",
  CHANGE_PREPARE_TEXT: "changePrepareText",
  CLEAR_STUDENTS: "clearStudents",
  CLEAR_EXAMS: "clearExams",
  CHANGE_EXAM_ID: "changeExamId",
  CHANGE_ANSWER_SHEET: "changeAnswerSheet",
  CHANGE_PART_ANSWER: "changePartAnswer",
};
const mutations: MutationTree<State> = {
  changeExamStatus(state, status: ExamStatus) {
    state.examStatus = status;
    logger.info(
      `change exam status:${status};examId:${
        state.examId
      }; student:${JSON.stringify(state.student || {})}`
    );
  },
  changeStudents(state, students: Array<any>) {
    state.students = students;
  },
  changeStudent(state, student: Student) {
    state.student = student;
  },
  changeConnectInfo(state, connectInfo) {
    state.proctorMain = connectInfo.isMain;
    state.mac = connectInfo.mac ?? state.mac;
    state.currentSerial = connectInfo.serialNo ?? state.currentSerial;
    state.localIp = connectInfo.localIp ?? state.localIp;
    state.localExamServicesPort =
      connectInfo.localExamServicesPort ?? state.localExamServicesPort;
    state.proctorStatus = connectInfo.proctorStatus;
    state.proctorIp = connectInfo.proctorIp;
    state.proctorDomain = connectInfo.proctorDomain;
  },
  changeActiveExams(state, activeExams) {
    if (!state.currentActiveExams) {
      state.currentActiveExams = {} as any;
    }
    state.currentActiveExams!.active_exam = activeExams;
  },
  changeCurrentSerial(state, currentSerial) {
    state.currentSerial = currentSerial;
  },
  changePrepareText(state, prepareText) {
    state.prepareText = prepareText;
  },
  clearStudents(state) {
    state.students = [];
    state.student = null;
  },
  clearExams(state) {
    state.examId = undefined;
    state.currentActiveExams = null;
    state.papers = [];
  },
  changePapers(state, papers) {
    let _papers = papers.map((paperObj: any) => {
      // todo: 这边有total_question_num，paper_group 未能从json里面拿到
      const _paper = {
        ...paperObj,
        id: paperObj.paper.id,
        exam_id: state.examId,
        name: paperObj.paper.name,
        total_score: paperObj.paper.total_score,
        total_question_num: paperObj.paper.total_question_num,
        paper_group: {
          id: paperObj.paper.paper_group.id,
          name: paperObj.paper.paper_group.name,
        },
        paper_parts: paperObj.parts,
      };
      return _paper;
    });
    const questionOrderType =
      state.currentActiveExams?.active_exam?.question_order_type || 0;
    _papers = _papers.map((paper: any) => {
      paper.paper_parts.forEach((part: any) => {
        if (questionOrderType > 0) {
          // 当试卷为乱序时题目进行乱序
          const shuffleQuestions = _.shuffle(part.questions);
          part.questions = shuffleQuestions;
        }
        part.questions.forEach((question: any) => {
          if (questionOrderType > 0) {
            if (
              question.question_params?.options &&
              question.question_params.options.length > 0
            ) {
              const shuffleOptions = _.shuffle(
                question.question_params.options
              );
              question.question_params.options = shuffleOptions;
            }
            // 题目为套题时单选多选选项乱序
            if (question.type === 11) {
              question.sub_questions.forEach((subQuestion: any) => {
                if (
                  subQuestion.question_params?.options &&
                  subQuestion.question_params.options.length > 0
                ) {
                  const shuffleOptions = _.shuffle(
                    subQuestion.question_params.options
                  );
                  subQuestion.question_params.options = shuffleOptions;
                }
              });
            }
          }
        });
      });
      return paper;
    });
    state.papers = _papers;
  },
  changeExamId(state, examId) {
    state.examId = examId;
  },
  changeAnswerSheet(state, answerSheet) {
    if (state.currentActiveExams) {
      state.currentActiveExams.answer_sheet = answerSheet;
    }
  },
  changePartAnswer(state, answerObj) {
    if (
      answerObj.student_id != state.student?.student_id ||
      answerObj.exam_id !== state.examId
    ) {
      return;
    }
    state.currentActiveExams &&
      (state.currentActiveExams!.answer_sheet = answerObj);
    if (!state.papers) {
      return;
    }
    const { answers = [] } = answerObj;
    if (answers.length <= 0) {
      return;
    }

    if (state.papers) {
      for (let i = 0; i < state.papers.length; i++) {
        const paper = state.papers[i];
        if (paper.paper_parts) {
          for (let j = 0; j < paper.paper_parts.length; j++) {
            const part = paper.paper_parts[j];
            part.questions.forEach((q) => {
              findSetAnswer(q, paper, answers);
              if (q.sub_questions && q.sub_questions.length > 0) {
                q.sub_questions.forEach((subQuestion: any) =>
                  findSetAnswer(subQuestion, paper, answers)
                );
              }
            });
          }
        }
      }
    }
  },
};
const findSetAnswer = (question: any, paper: any, answers: any) => {
  question.paper_id = paper.id;
  const answer = answers.find(
    (an: any) => an.paper_id === paper.id && an.question_id === question.id
  );
  if (answer) {
    question.correctness = answer.correctness;
    question.answerScore = answer.score || 0;
    question.answer_params = answer.answer_params;
  }
};

export default mutations;
