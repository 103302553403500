export interface State {
  /** 考试id*/
  examId?: string;
  /** 本级Mac*/
  mac: string;
  /** 监考机ip*/
  proctorIp: string;
  /** 监考机连接状态*/
  proctorStatus: boolean;
  proctorDomain?: string;
  proctorMain?: boolean;
  /** 本机ip */
  localIp?: string;
  /** 本地考试服务的端口 */
  localExamServicesPort?: number;
  /** 考试的学生信息*/
  student?: Student | null;
  /** 所有需要参加考试的学生信息*/
  students?: Array<Student>;
  // 当前编号
  currentSerial?: string;
  // 准备页面的提示文字
  prepareText?: string;
  // 考试状态
  examStatus: ExamStatus;
  /** 当前激活的考试*/
  currentActiveExams?: {
    active_exam: {
      id: string;
      name: string;
      total_score: number;
      pass_score: number;
      time: number;
      status: number;
      start_time: Date | string;
      end_time: Date | string;
      exam_time_type: number; // 考试类型 0 集体开始 1 自由开始
      question_order_type: number; // 题目是否乱序 0不是 1 是
      exam_type: string;
    };
    answer_sheet?: {
      exam_id: number;
      id?: number;
      started_at?: Date;
    };
  } | null;
  /**
   * 考试内容
   */
  papers?: Array<{
    id: number | string;
    exam_id: number;
    paper_group_id: number;
    name: string;
    total_score: number;
    pass_score: number;
    total_question_num: number;
    total_answer_question_num?: number;
    level?: number;
    school_id?: number | string;
    paper_group: {
      id: number;
      name: string;
    };
    paper_parts?: Array<Parts>;
  }>;
}

export interface Student {
  student_id: number;
  student_name: string;
  school_id: number;
  school_name: string;
  school_class_id: number;
  school_class_name: string;
  grade: number;
}

export enum ExamStatus {
  None = 0,
  DistributeExamPapersFailed,
  DistributeExamPapersIng,
  DistributeExamPapersSuccess,
  CanLoginFailed,
  CanLoginIng,
  CanLoginSuccess,
  StartExamFailed,
  StartExamIng,
  StartExamSuccess,
  FinishExamFailed,
  FinishExamIng,
  FinishExamSuccess,
}

const state: State = {
  mac: "000000",
  proctorIp: "0:0:0:0",
  proctorStatus: false,
  proctorDomain: "",
  prepareText: "请等待监考主机连接",
  examStatus: ExamStatus.None,
  students: [],
};
export default state;
