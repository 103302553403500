import request from "../examRequest";

/**
 * request 请求参数
 * (
 *    http method, http 请求方法
 *    request relate path, 请求的相对路径
 *    server, 请求的服务，来源于 servers
 *    params, 请求参数
 *    need token 是否需要 token
 * )
 */

// 获取当前考试的学生
export async function getCurrentStudent(params: any) {
  return request("GET", "/local-exam-services/current/students", params);
}
// 考生登录
export async function studentLogin(params: any) {
  return request("POST", "/local-exam-services/student/login", params);
}
// 获取当前考试的信息
export async function getExamInfo(params: any) {
  return request("GET", "/local-exam-services/exam/getExamInfo", params);
}
// 获取当前考试的试卷
export async function getPaper(params: any) {
  return request("GET", "/local-exam-services/exam/getPaper", params);
}
// 下载试卷
export async function downloadPaper(params: any) {
  return request(
    "POST",
    "/local-exam-services/exam/downloadPaper",
    params,
    1000 * 60 * 5
  );
}
// 获取答案
export async function getAnswer(params: any) {
  return request("GET", "/local-exam-services/exam/getAnswers", params);
}
// 保存答案
export async function saveAnswers(params: any) {
  return request(
    "POST",
    "/local-exam-services/exam/saveAnswers",
    params,
    1000 * 60
  );
}
// 获取答案
export async function getAnswers(params: any) {
  return request("GET", "/local-exam-services/exam/getAnswers", params);
}
export async function downloadAnswers(params: any) {
  return request(
    "POST",
    "/local-exam-services/exam/downloadAnswers",
    params,
    1000 * 60 * 5
  );
}
// 上传文件
export async function uploadResource(
  exam_id: string,
  student_id: string,
  params: any
) {
  return request(
    "PUT",
    `/local-exam-services/exam/uploadResource?exam_id=${exam_id}&student_id=${student_id}`,
    params,
    1000 * 60 * 5
  );
}
// 提交试卷
export async function requestSubmitExam(params: any) {
  return request(
    "POST",
    "/local-exam-services/exam/submitExam",
    params,
    1000 * 60 * 60
  );
}
// 设置设备编号
export async function setDeviceNum(params: any) {
  return request("POST", "/local-exam-services/exam/setDeviceNum", params);
}
// 考生登出
export async function logout(params: any) {
  return request("POST", "/local-exam-services/student/logout", params);
}

// 开始考试
export async function requestStartExam(params: any) {
  return request("POST", "/local-exam-services/exam/startExam", params);
}
